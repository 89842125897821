/** Error  handlers */

/**
 * Exception to handle non 200-299 HTTP status codes on responses from our API.
 * 
 * @param {Object} apiResponse - The raw fetch response from our API server. 
 */
function APIError(message, statusCode) {
    this.name = "APIError";
    this.message = message;
    this.status = statusCode;

    try {
        this.json = JSON.parse(this.message);
    } catch {
        this.json = "Unable to parse JSON data";
    }
    if ("captureStackTrace" in Error)
        Error.captureStackTrace(this, APIError);
    else
        this.stack = (new Error()).stack;
}
APIError.prototype = Error.prototype;

export {
    APIError,
}
