/**
 * Vue3 Application-creation file.
 */
import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router'
import store from './store'
import Notifications from '@kyvg/vue3-notification';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckCircle, faTimesCircle, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faCheckCircle, faTimesCircle, faSpinner)

const app = createApp(App)
    .use(router)
    .use(store)
    .use(Notifications)
    .component("font-awesome-icon", FontAwesomeIcon)
    .mount("#app");

export default app;
