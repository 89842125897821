/**
 * Vuex4 store for Vue3.
 *
 */
import { createStore } from 'vuex';
import mutations from './mutations.js';
import actions from './actions.js';

export default createStore({
    state() {
        return {
        //     invoice: {
        //         id: 1,
        //         total: 385.24,
        //         balance_due: 385.24,
        //         due_date: "December 31, 2021",
        //         currency: "USD",
        //         bill_to_address: {
        //             name: "Very Good MSP Customer",
        //             address: "123 Main Street",
        //             locality: "CoolTown",
        //             region: "CA",
        //             country: "US",
        //             postal_code: "12345",
        //             po_box: "PO Box 123",
        //             phone_number: "+15551234"
        //         }
        //     },
            company: {
                name: "GeekTek MSP",
                payment_gateway: "TokenPay",
                merchant_public_key: "tokenpay8878api20211212031230737",
            },
            payment: {
                accountname: "",
                payment_method: "CC",
                amount: null,
                currency: "USD",
                token: "",
            },
            posted_payments: [
                // {
                //     authorized_amount: 385.24,
                //     masked_ccard: "**************0005",
                //     transaction_id: "EF527gH04512JK",
                // }
            ],
            // branding: {
            //     logo: "https://www.gozynta.com/resources/logos/gozynta-logo-color.ea178a7142f49d2e1291b7e7b1e53d2c.svg",
            //     name: "GeekTek MSP",
            //     website: "https://www.geektek.com",
            //     mailing_address: "123 Main St, CoolTown, MA",
            //     phone: ""
            // },
            errors: [
                // {
                //     error_id: 1,
                //     message: "test"
                // }
            ],
            payment_submit_in_progress: false
        }
    },
    mutations,
    actions,
});
