/**
 * @file Vuex store mutations.
 * @module store/mutations
 *
 * Each one of these functions (referred to as a mutation) should be designed to
 * mutate the state once in an appropriate SYNCHRONOUS manner.  Each mutation's
 * function object may not be called directly and must be triggered via a
 * `store.commit(mutation_name)` call.
 *
 * Relevant documentation: https://vuex.vuejs.org/guide/mutations.html
 */

/**
 * A hacky utility method to rapidly test updating the state using a proper state life cycle
 * methedology.
 *
 * @param {Object} state The current state, handled by commit.
 * @param {Object} data Any object with keys/data pairs that you want to update the state with.
 *
 * Nick: This should be replaced, and we should write custom mutations for each specific
 * state modification.
 */
const updateState = (state, data) => {

    Object.keys(data).forEach((key) => {
        state[key] = data[key];
    });
};


export default {
    updateState,
};
