<template>
  <div id="app">
    <router-view />
    <notifications />
  </div>
</template>

<script>

export default {
    beforeCreate() {
        this.$store.dispatch('initApp');
    }
}

</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  background-color: #f6f6f6;
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: fit-content;
  margin: auto !important;
}

.container > div {
  width: 600px;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

hr.solid {
  border-top: 2px solid #bbb;
}
</style>
