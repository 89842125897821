/** Vuex store actions */
import { router } from '../router';
import services from './services';
import { APIError } from './exceptions';

/**
 * Initializes the application and fetches all relevant data.
 *
 * @param {Object} dispatch - Vuex store dispatch function.
 *
 */
const initApp = async ({ commit, dispatch }) => {
    const config = await fetch('/js/config/config.json').then((response) => response.json())

    commit('updateState', {config: config})

    await router.isReady()
    if (router.currentRoute.value.params.id) {
        dispatch('getInvoiceDetails', router.currentRoute.value.params.id);
        dispatch('getBrandingDetails', window.location.host);
    }
};

/**
 * Gets the details of an invoice by the invoices `id`.
 *
 * @param {Object} commit - Vuex store commit function.
 * @param {String} id The id of the invoice we want to fetch details for.
 */
const getInvoiceDetails = async ({ commit }, id) => {
    try {
        let result = await services.getInvoiceDetails(id);

        commit('updateState', {invoice: result.invoice});
        commit('updateState', {company: result.company});
        commit('updateState', {posted_payments: result.posted_payments})
    }
    catch (e) {
        /* This will be extended later - there will be more ifs inside of the instanceof check, as well as an else on the end. */
        if (e instanceof APIError) {
            if (e.status === 404 || e.status === 422) {
                commit('updateState', {invoice: false})
            }
        } else {
            console.log("Unexpected error: ", e)
        }
    }
};

/**
 * Posts payment information to our API for gateway-payment processing.
 *
 * @param {Object} commit - Vuex store commit function.
 * @param {Object} state - Vuex current store state.
 * @param {String} id The id of the invoice we want to fetch details for.
 */
const postInvoicePayment = async ({ commit, state }, tokenPayToken) => {
    /** TODO: refactor/cleanup. */
    try {
        let payload = state.payment
        payload.token = tokenPayToken
        let result = await services.postInvoicePayment(state.invoice.id, payload);
        let new_payments = [...state.posted_payments, result.payment];
        commit('updateState', {posted_payments: new_payments});
        commit('updateState', {transaction: result.payment});
        commit('updateState', {invoice: result.invoice});
        router.push({ name: "ConfirmationPage", params: {id: result.invoice.id, transaction_id: result.payment.transaction_id} })
    }
    catch (e) {
        const new_error_id = new Date().getTime()
        const new_errors = [...state.errors, { error_id: new_error_id, message: e.message }]

        commit('updateState', {errors: new_errors});
        router.push({ name: "ErrorPage", params: {error_id: new_error_id }})
    }
    finally {
        commit('updateState', {payment_submit_in_progress: false})
    }
};


const getBrandingDetails = async ({ commit }, host) => {
    try {
        let result = await services.getBrandingDetails(host);
        commit('updateState', {branding: result})
    } catch (e) {
        if (e instanceof APIError) {
            if (e.status === 404 || e.status === 422) {
                commit('updateState', {branding: false})
            }
        } else {
            console.log("Unexpected error: ", e)
        }
    }
}

export default {
    initApp,
    getInvoiceDetails,
    postInvoicePayment,
    getBrandingDetails,
}
