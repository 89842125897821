import { APIError } from "./exceptions.js";

/** Helpers to directly interact with our API. */

/**
 * Handler to parse the JSON retrieved from our API.
 *
 * @param {Promise} request A fetch call to an endpoint for our API.
 * @returns {Object} An object with the contents of the response from our API.
 *
 */
const handleResponse = async (request) => {
    const rawResponse = await request;
    let text = await rawResponse.text();

    if (!rawResponse.ok) {
        try {
            text = JSON.parse(text).detail
        } catch(e) {
            console.log(e)
        }
        /** Catches non 200-299 status code responses. */
        throw new APIError(text, rawResponse.status);
    }

    try {
        return JSON.parse(text);
    } catch (err) {
        throw new Error("JSON response failed to parse.", {cause: err});
    }
};

/**
 * Fetches all the details for an invoice with a specific ID.
 *
 * @param {String} id String-ID of the invoice we want to fetch details for.
 * @returns ?
 */
const getInvoiceDetails = async (id) => {
    return handleResponse(fetch(`/api/payments/invoices/${id}`));
};

/**
 * Submits payment information for a new payment on an invoice with a specific ID.
 *
 * @param {String} id String-ID of the invoice we want to fetch details for.
 * @param {Object} body Object containing necessary payment details.
 * @returns ?
 */
const postInvoicePayment = (id, body) => {
    return handleResponse(
        fetch(`/api/payments/invoices/${id}/payment`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
            }
        })
    );
};


const getBrandingDetails = (host) => {
    return handleResponse(fetch(`/api/branding/company/domains/${host}`));
}


export default {
    getInvoiceDetails,
    postInvoicePayment,
    getBrandingDetails,
};
