/**
 * Routes file.
 *
 * This maps the URL's to each component.  We're using lazy loading.
 *
 * Nick: I'm not a fan of the lazy loading thing, it's hard to read - I don't know
 * whether it gets us a suitable performance gain.
 */
import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'RootPage',
        // route level code-splitting
        // this generates a separate chunk for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../pages/NotFoundPage.vue')
    },
    {
        path: '/:id',
        name: 'InvoicePage',
        // route level code-splitting
        // this generates a separate chunk for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../pages/InvoicePage.vue')
    },
    {
        path: '/:id/confirmation/:transaction_id',
        name: 'ConfirmationPage',
        component: () => import(/* webpackChunkName: "about" */ '../pages/ConfirmationPage.vue')
    },
    {
        path: '/:id/error/:error_id',
        name: 'ErrorPage',
        component: () => import(/* webpackChunkName: "about" */ '../pages/ErrorPage.vue')
    },
    {
        path: '/:path(.*)',
        name: 'NotFoundPage',
        component: () => import(/* webpackChunkName: "about" */ '../pages/NotFoundPage.vue')
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
})

export {
    router
}
